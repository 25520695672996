import * as React from 'react';
import {
    AppBar, Toolbar, Typography
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import MainMenu from './Menu'

function AppToolbar() {

    const [title, setTitle] = React.useState(null);

    const location = useLocation();

    /*
    React.useEffect(() => {
        const path = window.location.pathname;
        const botNameFromUrl = path.split('/')[1];

        axios.post(`${process.env.REACT_APP_API}/api/telegram/client/me`, {
            initData: window.Telegram.WebApp.initData,
            bot: botNameFromUrl,
        }).then(payload => {
            if(payload?.data?.id){
                setUser(payload?.data)
            }
        })
    }, [])\*/

    React.useEffect(() => {
        const pathname = location.pathname.split('/').slice(2).join('/')

        switch (pathname) {
            case '':
            case '/':
                setTitle('Кабінет тренера')
                break;
            default:
                setTitle('Кабінет тренера')
        }
    }, [location.pathname]);

    return (
        <AppBar position="static" color="error">
            <Toolbar>
                <MainMenu />

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{title}</Typography>
            </Toolbar>
        </AppBar>
    );
}

export default AppToolbar;
