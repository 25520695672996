import React, { useState, useEffect, createContext } from 'react';
import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,

} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useTournamentContext } from './../../context';
import axios from '../../utils/axios';
import { useParams } from "react-router-dom";
import Participant from './participant'

function HomePage() {
    const { id_tournament } = useParams();
    const { user, apiURL } = useTournamentContext();
    const [ tournament, setTournament ] = useState({})
    const [ participants, setParticipants ] = useState([])
    const [ proposals, setProposals ] = useState([])

    useEffect(() => {
        axios.get(apiURL(`tournament/${id_tournament}`)).then(payload => {
            setTournament(payload?.data)
        })
        axios.get(apiURL(`tournament/${id_tournament}/participants`)).then(payload => {
            setParticipants(payload?.data)
        })
        axios.get(apiURL(`tournament/${id_tournament}/proposals`)).then(payload => {
            setProposals(payload?.data)
        })
    }, [])

    return (<>
        <Card style={{padding: '0px 10px'}}>
            <Typography variant="h5">
                {tournament?.name}, {tournament?.date_formatted}
            </Typography>

            <Typography>

            </Typography>
        </Card>
        {participants.map(participant => (
            <Participant
                participant={participant}
                tournament={tournament}
            />
        ))}
        {proposals.map(proposal => (
            <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {proposal.name} ⏳
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "⭐ Тренери" на клавіатурі знизу екрана</li>
                        <li>Натисніть на кнопку "🆕 Додати тренера"</li>
                        <li>Напишіть імʼя нового тренера</li>
                        <li>Готово! Тепер можна додати йому фото, опис, зарплатню і т.п.</li>
                    </ol>
                    <Link to="/coach">Детальніше про редагування тренерів</Link>
                </AccordionDetails>
            </Accordion>
        ))}
    </>);
}

export default HomePage;
