import { useState, useEffect, createContext } from 'react';
import {
    Box
} from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage'
import Toolbar from './Toolbar'
import { useTournamentContext } from './context';

function App() {

    const { user } = useTournamentContext();

    if(!user){
        return (<></>)
    }

    return (
        <Router>
            <Box sx={{ flexGrow: 1 }}>
                <Toolbar />
                <Routes>
                    <Route path="/:bot/:id_tournament" element={<HomePage />} />
                </Routes>
            </Box>
        </Router>
    );
}

export default App;
