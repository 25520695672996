import React, { createContext, useState, useEffect, useContext } from 'react';

import axios from './utils/axios';

const TournamentContext = createContext();

export const TournamentProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const path = window.location.pathname;
    const bot = path.split('/')[1];

    const apiURL = url => {
        return `${process.env.REACT_APP_API}/api/festival-bot/${bot}/${url}`
    }

    useEffect(() => {
        setLoading(true);
        axios.get(apiURL('me')).then(payload => {
            setLoading(false);
            if(payload?.data?.id){
                setUser(payload?.data)
            }
        })
    }, [])

    const value = { user, bot, loading, apiURL };

    return <TournamentContext.Provider value={value}>{children}</TournamentContext.Provider>;
};

export const useTournamentContext = () => useContext(TournamentContext);
